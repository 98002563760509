import React, { createContext, useState } from "react";
import { useUpdateEffect } from "../hooks/useUpdateEffect";

export const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [auth, setAuth] = useState({
    error: null,
    token: localStorage.getItem("access_token"),
    username: localStorage.getItem("username"),
    userid: localStorage.getItem("userid"),
    fname: localStorage.getItem("fname"),
    lname: localStorage.getItem("lname")
  });

  function logout() {
    setAuth({
      error: null,
      token: null,
      username: null,
      userid: null,
      fname: null,
      lname: null
    });
  }
  useUpdateEffect(() => {
    if (auth.token === null && auth.username === null && auth.userid === null) {
      localStorage.clear();
    }
  }, [auth]);
  return (
    <AuthContext.Provider value={{ auth, setAuth, logout }}>
      {children}
    </AuthContext.Provider>
  );
}
