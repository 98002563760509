import { useState } from "react";
import axios from "axios";
import { useUpdateEffect } from "./useUpdateEffect";
import { useAuth } from "./useAuth";
import { useIsMounted } from "./useIsMounted";

export const usePellaApi = (
  url,
  initialData = {},
  method = "GET",
  customendpoint,
  customHeaders = {},
  useBaseUrl
) => {
  const [data, setData] = useState(initialData);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState(null);
  const [call, setCall] = useState(null); //may contain body or an empty object
  //literal used to trigger API call
  const {
    auth: { token },
  } = useAuth();

  const isMounted = useIsMounted();

  function resetHook() {
    setData(initialData);
    setIsLoading(false);
    setIsError(false);
    setError(null);
  }

  useUpdateEffect(() => {
    const fetchData = async () => {
      setIsError(false);
      setIsLoading(true);
      try {
        const result = await axios({
          method,
          url: customendpoint
            ? customendpoint
            : useBaseUrl
            ? process.env.REACT_APP_BASE_URL + url
            : process.env.REACT_APP_API + url,
          data: { ...call },
          headers: {
            Authorization: `Bearer ${token}`,
            "Ocp-Apim-Subscription-Key": process.env.REACT_APP_SUBKEY,
            ...customHeaders,
          },
        });
        if (isMounted.current) {
          setData(result.data);
        }
      } catch (error) {
        if (isMounted.current) {
          setIsError(true);
          error &&
            error.response &&
            error.response.data &&
            setError(error.response.data.message);
        }
        //console.error(error.toJSON());
      }
      if (isMounted.current) {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [method, call, isMounted]);

  return [{ data, isLoading, isError, error }, setCall, resetHook];
};
