import React from "react";
import ReactModal from "react-aria-modal";
import styles from "../css/Modal.module.css";
import { ReactComponent as Close } from "../icons/close.svg";

export default function Modal({
  titleText,
  customDialogClass,
  heading,
  onExit,
  disableClose,
  children,
}) {
  return (
    <ReactModal
      titleText={titleText}
      dialogClass={`${styles.modal} ${customDialogClass}`}
      onExit={onExit}
      getApplicationNode={() => document.getElementById("root")}
      initialFocus="#deactivate"
      verticallyCenter={true}
      underlayClickExits={!disableClose}
      escapeExits={!disableClose}
    >
      <div className={`flex ${styles.header}`}>
        <h2 className={styles.title}>{heading}</h2>
        <button
          id="deactivate"
          className={`button-style-reset flex alctr ${styles.closeButton}`}
          onClick={() => onExit()}
          disabled={disableClose}
        >
          <Close
            viewBox="-3 -3 20 20"
            className={`${styles.closeIcon}`}
            aria-hidden="true"
          />
        </button>
      </div>
      <div className={styles.body}>{children}</div>
    </ReactModal>
  );
}
