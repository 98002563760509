import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import FocusTrap from "focus-trap-react";
import modalstyles from "./css/ModalForm.module.css";
import styles from "./css/Avatar.module.css";
import { ReactComponent as AvatarIcon } from "./icons/avatar-person.svg";
import { ReactComponent as Logout } from "./icons/logout.svg";
import { ReactComponent as User } from "./icons/user.svg";
import { ReactComponent as Close } from "./icons/close.svg";
import { ReactComponent as ShowPassword } from "./icons/showpassword.svg";
import { ReactComponent as HidePassword } from "./icons/hidepassword.svg";
import { useAuth } from "./hooks/useAuth";
import { usePellaApi } from "./hooks/usePellaApi";
import { useUpdateEffect } from "./hooks/useUpdateEffect";
import NotyfContext from "./context/NotyfContext";
import Modal from "./common/Modal";

function Avatar({ className }) {
  const history = useHistory();
  const notyf = useContext(NotyfContext);
  const [toggled, setToggled] = useState(false); //avatar dropdown
  const [passModal, setPassModal] = useState(false);
  const [currentPass, setCurrentPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [retypePass, setRetypePass] = useState("");
  const [showCurrentPass, setShowCurrentPass] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);
  const [showRePass, setShowRePass] = useState(false);

  const [readOnlyCurrentPass, setReadOnlyCurrentPass] = useState("true");
  const [readOnlyNewPass, setReadOnlyNewPass] = useState("true");
  const [readOnlyRetypePass, setReadOnlyRetypePass] = useState("true");

  const [logoutModal, setLogoutModal] = useState(false);

  function resetPassChangeForm() {
    setCurrentPass("");
    setNewPass("");
    setRetypePass("");
    setShowCurrentPass(false);
    setShowNewPass(false);
    setShowRePass(false);
  }
  const { auth, isAuthenticated, email, logout } = useAuth();

  const [
    {
      data: changePassRes,
      isLoading: changingPassword,
      isError: changePassErr,
      error: changePassErrorMsg,
    },
    changePassword,
  ] = usePellaApi("/auth/api/changepassword", null, "POST", false, {}, true);

  const { userid } = auth;
  const hide = !isAuthenticated;
  function updatePassword() {
    changePassword({ userid, oldpassword: currentPass, newpassword: newPass });
  }
  useUpdateEffect(() => {
    if (!changingPassword && changePassErr && changePassErrorMsg) {
      notyf.error(changePassErrorMsg);
      return;
    }
    if (!changingPassword && changePassRes) {
      notyf.success("Password changed");
      resetPassChangeForm();
      setPassModal(false);
    }
  }, [changingPassword, changePassRes, changePassErr, changePassErrorMsg]);

  return (
    <>
      <div className={`${className} ${styles.avatar} ${hide ? "hide" : ""}`}>
        <button
          id="avatarBtn"
          className={`button-style-reset ${styles.action}`}
          onClick={() => setToggled((t) => !t)}
        >
          <span
            className={styles.username}
          >{`${auth.fname} ${auth.lname}`}</span>
          <AvatarIcon className={styles.avatarIcon} aria-hidden="true" />
        </button>
        {toggled && (
          <FocusTrap
            focusTrapOptions={{
              clickOutsideDeactivates: true,
              onDeactivate: () => setToggled(false),
            }}
          >
            <div className={`${styles.avatarpopup} rounded-corner`}>
              <button
                id="closeBtn"
                className={`button-style-reset flex ${styles.action} ${styles.close}`}
                onClick={() => setToggled((t) => !t)}
              >
                <Close viewBox="-3 -3 20 20" aria-hidden="true" />
              </button>

              <div className={`grid ${styles.gridContainer}`}>
                <User aria-hidden="true" />
                <div className={`flex column ${styles.flexContainer}`}>
                  <h4>{auth.username}</h4>
                  <p>{email}</p>

                  <div className={styles.buttons}>
                    <button
                      id="changePwdBtn"
                      className={`button-style-reset  ${styles.action} ${styles.changepass}`}
                      onClick={() => setPassModal(true)}
                    >
                      Change Password
                    </button>
                    <button
                      id="logoutBtn"
                      className={`button-style-reset  ${styles.logout} ${styles.action} flex`}
                      onClick={() => {
                        setLogoutModal(true);
                      }}
                    >
                      <Logout aria-hidden="true" />
                      Logout
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </FocusTrap>
        )}
      </div>
      {passModal && (
        <Modal
          titleText="Change Password"
          heading="Change Password"
          onExit={() => {
            setReadOnlyCurrentPass(true);
            setReadOnlyNewPass(true);
            setReadOnlyRetypePass(true);
            setPassModal(false);
            resetPassChangeForm();
          }}
        >
          <form
            id="noac"
            onSubmit={(e) => {
              e.preventDefault();
              updatePassword();
            }}
            className={styles.form}
          >
            <label htmlFor="currentpass">Current Password</label>
            <div className={`flex margin-bottom`}>
              <input
                id="currentpass"
                type={
                  showCurrentPass ? "text" : currentPass ? "password" : "text"
                }
                className={`${modalstyles.input} ${styles.marginReset}`}
                value={currentPass}
                onChange={({ target }) => setCurrentPass(target.value)}
                required
                readOnly={readOnlyCurrentPass}
                onFocus={() => setReadOnlyCurrentPass(false)}
                onBlur={() => setReadOnlyCurrentPass(true)}
              />
              <button
                id="showBtn1"
                type="button"
                title={showCurrentPass ? "Hide Password" : "Show Password"}
                className={`button-style-reset ${styles.showPwdBtn}`}
                onClick={() => {
                  setShowCurrentPass((p) => !p);
                }}
                disabled={!currentPass}
              >
                {showCurrentPass ? (
                  <HidePassword
                    className={`${styles.eyeIcon}`}
                    aria-hidden="true"
                  />
                ) : (
                  <ShowPassword
                    className={`${styles.eyeIcon}`}
                    aria-hidden="true"
                  />
                )}
              </button>
            </div>
            <label htmlFor="newPass">New Password</label>
            <div className={`flex margin-bottom`}>
              <input
                type={showNewPass ? "text" : newPass ? "password" : "text"}
                className={`${modalstyles.input} ${styles.marginReset}`}
                id="newPass"
                value={newPass}
                onChange={({ target }) => setNewPass(target.value)}
                required
                autoComplete="new"
                readOnly={readOnlyNewPass}
                onFocus={() => setReadOnlyNewPass(false)}
                onBlur={() => setReadOnlyNewPass(true)}
              />
              <button
                id="showBtn2"
                type="button"
                title={showNewPass ? "Hide Password" : "Show Password"}
                className={`button-style-reset ${styles.showPwdBtn}`}
                onClick={() => {
                  setShowNewPass((p) => !p);
                }}
                disabled={!newPass}
              >
                {showNewPass ? (
                  <HidePassword
                    className={`${styles.eyeIcon}`}
                    aria-hidden="true"
                  />
                ) : (
                  <ShowPassword
                    className={`${styles.eyeIcon}`}
                    aria-hidden="true"
                  />
                )}
              </button>
            </div>
            <label htmlFor="retypepass">Re-type Password</label>
            <div className={`flex margin-bottom`}>
              <input
                type={showRePass ? "text" : retypePass ? "password" : "text"}
                className={`${modalstyles.input} ${styles.marginReset}`}
                id="retypepass"
                value={retypePass}
                onChange={({ target }) => setRetypePass(target.value)}
                required
                autoComplete="new"
                readOnly={readOnlyRetypePass}
                onFocus={() => setReadOnlyRetypePass(false)}
                onBlur={() => setReadOnlyRetypePass(true)}
              />
              <button
                id="showBtn3"
                type="button"
                title={showRePass ? "Hide Password" : "Show Password"}
                className={`button-style-reset ${styles.showPwdBtn}`}
                onClick={() => {
                  setShowRePass((p) => !p);
                }}
                disabled={!retypePass}
              >
                {showRePass ? (
                  <HidePassword
                    className={`${styles.eyeIcon}`}
                    aria-hidden="true"
                  />
                ) : (
                  <ShowPassword
                    className={`${styles.eyeIcon}`}
                    aria-hidden="true"
                  />
                )}
              </button>
            </div>
            <button
              id="saveBtn"
              type="submit"
              className={`pella-button ${modalstyles.action}`}
            >
              Save
            </button>
          </form>
        </Modal>
      )}
      {/* logout confirmation popup */}
      {logoutModal && (
        <Modal
          titleText="Logout"
          heading="Logout"
          onExit={() => setLogoutModal(false)}
        >
          <p className={`${styles.p}`}>Are you sure you want to logout?</p>
          <div className={`${styles.buttons}`}>
            <button
              type="button"
              id="cancel"
              className={`pella-button ${styles.download} ${styles.cancel}`}
              onClick={() => setLogoutModal(false)}
            >
              Cancel
            </button>
            <button
              id="yes"
              type="button"
              className={`pella-button ${styles.reboot} ${styles.yes}`}
              onClick={() => {
                setLogoutModal(false);
                logout();
                history.replace("/");
              }}
            >
              Yes
            </button>
          </div>
        </Modal>
      )}
    </>
  );
}
export default Avatar;
