import { useInterval } from "./useInterval";
import { useAuth } from "./useAuth";
import { useUpdateEffect } from "./useUpdateEffect";
import devLogger from "../utils/devLogger";
import { usePellaApi } from "./usePellaApi";
import { useMemo } from "react";

function calcRefreshPoint(exp) {
  // const delay = exp * 1000 - new Date().getTime() - 30 * 60 * 1000;
  // return delay < 10 * 60 * 1000 || delay > 2100000 * 1000 ? null : delay;
  const timeLeft = exp * 1000 - new Date().getTime();

  const delay =
    timeLeft > 5 * 60 * 1000 && timeLeft < 2100000 * 1000
      ? timeLeft - 3 * 60 * 1000
      : null;

  return delay;
}

export function useRefreshToken() {
  const { setAuth, exp, isAuthenticated } = useAuth();
  const userId = localStorage.getItem("userid");
  const [{ data: refTokRes }, refreshToken] = usePellaApi(
    "/auth/api/refreshtoken",
    null,
    "GET",
    false,
    { userId },
    true
  );

  const delay = useMemo(() => calcRefreshPoint(exp), [exp]);

  useInterval(() => refreshToken({}), isAuthenticated && exp ? delay : null);

  useUpdateEffect(() => {
    if (refTokRes) {
      devLogger(`refreshing token`);
      localStorage.setItem("access_token", refTokRes.token);
      devLogger(refTokRes && refTokRes.token);
      setAuth((o) => ({ ...o, token: refTokRes.token }));
    }
  }, [refTokRes]);
}
