import { useEffect, useRef, useState } from "react";
import devLogger from "../utils/devLogger";

export function useInterval(callback, delay) {
  const isTimerActive = useRef(false);
  const savedCallback = useRef();
  const [ts, setTs] = useState(false);
  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      setTs(true);
      isTimerActive.current = true;
      let id = setInterval(tick, delay);
      devLogger(`[timer ${id}] tick scheduled for ${delay}`);
      return () => {
        devLogger(`[timer ${id}] clearing interval`);
        clearInterval(id);
        isTimerActive.current = false;
        setTs(false);
      };
    }
  }, [delay]);

  return { isTimerActive, ts };
}
