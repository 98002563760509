import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import jwtDecode from "jwt-decode";
import devLogger from "../utils/devLogger";

export function useAuth() {
  const state = useContext(AuthContext);
  const isError = state.auth.status === "error";
  let decodedClaims, isValid;
  if (state.auth.token) {
    try {
      decodedClaims = jwtDecode(state.auth.token);
      if (new Date().getTime() < decodedClaims.exp * 1000) {
        isValid = true;
      } else {
        isValid = false;
      }
    } catch {
      if (process.env.NODE_ENV === "development") {
        devLogger("Decoding of token failed");
      }
    }
  }
  const isAuthenticated = state.auth.token && isValid;
  const role =
    state.auth.token && decodedClaims["https://api.delpheon.com/userRole"];
  const email =
    state.auth.token && decodedClaims["https://api.delpheon.com/email"];
  const exp = state.auth.token && decodedClaims["exp"];
  return {
    ...state,
    role,
    email,
    exp,
    isError,
    isAuthenticated,
  };
}
