import React from "react";
import {
  Link,
  BrowserRouter as Router,
  Route,
  Switch,
  NavLink,
} from "react-router-dom";
import styles from "./css/App.module.css";
import { useAuth } from "./hooks/useAuth";
import { Spinner } from "./Spinner";
import Avatar from "./Avatar";
import ForgotPassword from "./ForgotPassword";
import { useRefreshToken } from "./hooks/useRefreshToken";
import Notifications from "react-notify-toast";

const AuthenticatedBundle = React.lazy(() => import("./AuthenticatedApp"));
const UnauthenticatedBundle = React.lazy(() => import("./UnauthenticatedApp"));

function App() {
  const { isAuthenticated, role } = useAuth();
  useRefreshToken();
  return (
    <Router>
      <React.Suspense fallback={<Spinner />}>
        <Switch>
          <Route exact path="/reset">
            <ForgotPassword />
          </Route>
          <Route path="/">
            <div className="flex column h100">
              <header className={styles.header}>
                <Link to="/" className={styles.logo}>
                  <img
                    src={process.env.PUBLIC_URL + "/icon-72x72.png"}
                    alt="Pella"
                  ></img>
                </Link>
                {isAuthenticated && (
                  <nav className={styles.nav}>
                    {role === "superadmin" && (
                      <NavLink to="/adminusers" activeClassName={styles.active}>
                        Admin Users
                      </NavLink>
                    )}
                    <NavLink to="/homeusers" activeClassName={styles.active}>
                      Home Users
                    </NavLink>
                    {/* {role === "superadmin" && (
                      <NavLink to="/firmware" activeClassName={styles.active}>
                        Upload Firmware
                      </NavLink>
                    )} */}
                    <NavLink to="/" activeClassName={styles.active} exact>
                      Bridge Setup
                    </NavLink>
                    <NavLink
                      to="/helpcontents"
                      activeClassName={styles.active}
                      exact
                    >
                      Help Contents
                    </NavLink>
                  </nav>
                )}
                <Avatar className={styles.floatRight} />
              </header>
              <main className={`grow ${styles.main}`}>
                {isAuthenticated ? (
                  <AuthenticatedBundle />
                ) : (
                  <UnauthenticatedBundle />
                )}
                <Notifications options={{ zIndex: 70000000, top: "10%" }} />
              </main>
            </div>
          </Route>
        </Switch>
      </React.Suspense>
    </Router>
  );
}

export default App;
