import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import jwtDecode from "jwt-decode";
import styles from "./css/ForgotPassword.module.css";
import { useUpdateEffect } from "./hooks/useUpdateEffect";
import { usePellaApi } from "./hooks/usePellaApi";
import { Spinner } from "./Spinner";
import devLogger from "./utils/devLogger";

function ForgotPassword() {
  const location = useLocation();
  const token = location.search && queryString.parse(location.search).token;
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  let decodedClaims;
  let isValid;

  if (token) {
    try {
      decodedClaims = jwtDecode(token);
      if (new Date().getTime() < decodedClaims.exp * 1000) {
        isValid = true;
      } else {
        isValid = false;
      }
    } catch {
      if (process.env.NODE_ENV === "development") {
        devLogger("Decoding of token failed");
      }
    }
  }
  const email = token && decodedClaims["https://api.delpheon.com/email"];
  devLogger(email);
  const [
    {
      data: resPassRes,
      isLoading: resPassLoading,
      isError: resPassError,
      error: resPassErrorMsg,
    },
    generatePassword,
  ] = usePellaApi(
    "",
    null,
    "POST",
    process.env.REACT_APP_GENERATEPWD_URL,

    {
      Authorization: undefined,
      "Ocp-Apim-Subscription-Key": process.env.REACT_APP_LOGIN_SUBKEY,
    }
  );

  function handleResetPassword() {
    generatePassword({ useremail: email });
  }
  useEffect(() => {
    return () => {
      setShowSuccessMessage(false);
      setShowErrorMessage(false);
    };
  }, []);

  useUpdateEffect(() => {
    if (!resPassLoading && resPassRes) setShowSuccessMessage(true);
  }, [resPassLoading, resPassRes]);

  useUpdateEffect(() => {
    if (!resPassLoading && resPassError && resPassErrorMsg)
      setShowErrorMessage(true);
  }, [resPassLoading, resPassError, resPassErrorMsg]);

  return (
    <div className={`flex column h100 ${styles.container}`}>
      <div className={`${styles.textCenter}`}>
        <img src={process.env.PUBLIC_URL + "/icon-72x72.png"} alt="Pella"></img>
        <img
          width="150px"
          src={process.env.PUBLIC_URL + "/insynctive.png"}
          alt="Insynctive"
        ></img>
      </div>

      {isValid !== false && !showSuccessMessage && !showErrorMessage && (
        <button
          className={`pella-button ${styles.confirm}`}
          onClick={() => handleResetPassword()}
        >
          Confirm Password Reset
        </button>
      )}
      {isValid === false ? (
        <div className={`${styles.textCenter}`}>
          Something went wrong.Please try again later.
        </div>
      ) : (
        (showSuccessMessage || showErrorMessage) && (
          <div
            className={`${styles.textCenter} ${
              resPassRes ? styles.success : styles.error
            }`}
          >
            {resPassRes ? resPassRes.message : resPassErrorMsg}
          </div>
        )
      )}
      {resPassLoading && <Spinner />}
      <div className={`${styles.textCenter}`}>
        <div>NEED ASSISTANCE OR HAVE QUESTIONS?</div>
        <div>
          <b>We are here to help!</b>
        </div>
        <div>Visit Pella.com/Insynctive </div>
        <div>or call</div>
        <div>1-800-374-4758</div>
      </div>
    </div>
  );
}

export default ForgotPassword;
